import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {keys} from 'lodash-es'
import * as typography from '../typography/typography'


export const stateVariants = {
  valid: css`
    color: ${({theme}) => theme.colors.textInverted};
  `,
  invalid: css`
    color: ${({theme}) => theme.colors.negative};
  `,
}

const StyledHelperContainer = styled.div`
  width: 100%;
`

const StyledHelper = styled.span`
  display: block;
  padding-left: 0.5rem;
  text-align: left;
  color: ${({theme}) => theme.colors.textInverted};
  ${typography.h6};

  ${({state}) => state && stateVariants[state]}
`

const HelperText = ({state, children, ...props}) => {
  return (
    <If condition={children}>
      <StyledHelperContainer {...props}>
        <StyledHelper state={state}>{children}</StyledHelper>
      </StyledHelperContainer>
    </If>
  )
}

HelperText.propTypes = {
  state: PropTypes.oneOf(keys(stateVariants)),
  children: PropTypes.string,
}

export default HelperText
