import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import styled, {css} from 'styled-components'
import media from '../../utils/media'
import Text from '../typography/Text'
import Button from '../Button'
import AppShell from './AppShell'
import ContentContainer from './ContentContainer'


const StyledContentContainer = styled(ContentContainer)`
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.75rem 3rem 1.875rem 3rem;

  @supports (backdrop-filter: 0px) {
    backdrop-filter: 0px;
  }

  ${media.down('mobile')(css`
    padding: 2.375rem 2.125rem 1.375rem 1.5rem;
  `)}
`

const ThankYouPage = ({pageData}) => {
  return (
    <AppShell
        title={pageData?.title}
        lang={pageData?.lang}
        seo={pageData?.Seo}
        meta={pageData?.preventIndexing ? [{name: 'robots', content: 'noindex,nofollow'}] : []}
        mobileBackground={pageData?.mobileBackground?.localFile?.publicURL}
        desktopBackground={pageData?.desktopBackground?.localFile?.publicURL}
        linkGdpr={pageData?.linkGdpr}
    >
      <If condition={!isEmpty(pageData?.content)}>
        <StyledContentContainer>
          <Text
              component="div"
              color="secondary"
              dangerouslySetInnerHTML={{__html: pageData.thankYouContent?.data?.childMarkdownRemark?.html}}
          />
        </StyledContentContainer>
      </If>
      <If condition={!isEmpty(pageData?.ThankYouButton?.text) && !isEmpty(pageData?.ThankYouButton?.link)}>
        <Button type="button" to={pageData.ThankYouButton.link}>
          {pageData.ThankYouButton.text}
        </Button>
      </If>
    </AppShell>
  )
}

ThankYouPage.propTypes = {
  pageData: PropTypes.object,
}

export default ThankYouPage
