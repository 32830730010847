import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import VisualTextInput from '../visual/form/TextInput'
import HelperText from '../visual/form/HelperText'


const TextInput = ({name, label, helperText, required, fieldProps, ...props}) => {
  const {register, getFieldState} = useFormContext()
  const {errors} = getFieldState(name)
  return (
    <div>
      <VisualTextInput
          name={name}
          state={get(errors, name) ? 'invalid' : 'valid'}
          label={label}
          helperText={!get(errors, name) ? helperText : ''}
          required={required}
          {...register(name, fieldProps)}
          {...props}
      />
      <ErrorMessage as={HelperText} name={name} state="invalid" />
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
}

export default TextInput
