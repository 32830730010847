import PropTypes from 'prop-types'
import {get, includes} from 'lodash-es'
import {For} from 'babel-plugin-jsx-control-statements'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import CheckboxButton from '../visual/form/CheckboxButton'
import HelperText from '../visual/form/HelperText'
import AnswersContainer from '../visual/components/AnswersContainer'


const CheckboxGroup = ({name, options, helperText, required, fieldProps, ...props}) => {
  const {register, getFieldState, watch} = useFormContext()
  const {errors} = getFieldState(name)
  return (
    <>
      <AnswersContainer
          helperText={!get(errors, name) ? helperText : ''}
          state={get(errors, name) ? 'invalid' : 'valid'}
      >
        <For each="option" of={options}>
          <CheckboxButton
              key={option.value}
              label={option.label}
              value={option.value}
              required={required}
              helperText={helperText}
              checked={includes(watch(name), option.value)}
              {...register(name, fieldProps)}
              {...props}
          />
        </For>
      </AnswersContainer>
      <ErrorMessage as={HelperText} name={name} state="invalid" />
    </>
  )
}

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
}

export default CheckboxGroup
