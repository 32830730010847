import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'


const StyledFormLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({theme}) => `calc(${theme.spacer} * 2.5) calc(${theme.spacer} * 2)`};
  width: 100%;

  & > div {
    width: calc(50% - 1rem);

    ${media.down('mobile')(css`
      width: unset;
    `)}
  }

  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const FormLayout = ({children}) => {
  return (
    <StyledFormLayout>
      {children}
    </StyledFormLayout>
  )
}

FormLayout.propTypes = {
  children: PropTypes.node,
}

export default FormLayout
