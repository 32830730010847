import {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {FormProvider, useForm} from 'react-hook-form'
import {isArray, isEmpty, map} from 'lodash-es'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Text from '../visual/typography/Text'
import Button from '../visual/Button'
import ContentContainer from '../visual/components/ContentContainer'
import AppShell from '../visual/components/AppShell'
import PaperPlaneIcon from '../assets/icons/paper-plane.svg'
import ConsentSection from '../visual/components/ConsentSection'
import FormContainer from '../visual/components/FormContainer'
import RadioGroup from '../form/RadioGroup'
import TextInput from '../form/TextInput'
import ThankYouPage from '../visual/components/ThankYouPage'
import CounterContainer from '../visual/components/CounterContainer'
import CheckboxGroup from '../form/CheckboxGroup'
import {parseInt} from '../utils/form'
import {INITIAL_CONSENT, useConsent} from '../hooks/useConsent'
import FormLayout from '../visual/components/FormLayout'
import Image from '../components/Image'
import {SK, t} from '../translations'


const schema = (lang) => yup.object().shape({
  answers: yup.mixed().when('$answers', (answers, schema) => {
    if (answers) {
      return schema.test('empty', t(lang, 'error.message.answer.empty'), (value) => {
        return !isEmpty(value)
      })
    }
  }),
  email: yup.string()
    .email(t(lang, 'error.message.email'))
    .when('$emailRequired', (emailRequired, schema) => {
      if (emailRequired) {
        return schema.required(t(lang, 'error.message.global.required'))
      }
    }),
  phone: yup.string().when('$phoneRequired', (phoneRequired, schema) => {
    if (phoneRequired) {
      return schema.required(t(lang, 'error.message.global.required'))
    }
  }),
  postCode: yup.string()
    .when('$postCodeRequired', (postCodeRequired, schema) => {
      if (postCodeRequired) {
        return schema.required(t(lang, 'error.message.global.required'))
      }
    })
    .when(['$isSk', '$postCodeRequired'], (isSk, postCodeRequired, schema) => {
      if (isSk) {
        return schema.test('length', t(lang, 'error.message.postCode.length'), (value) => {
          return value?.length === 5 || (!postCodeRequired && isEmpty(value))
        })
      }
    }),
  customField: yup.string().when('$customFieldRequired', (customFieldRequired, schema) => {
    if (customFieldRequired) {
      return schema.test('empty', t(lang, 'error.message.global.required'), (value) => {
        return !isEmpty(value)
      })
    }
  }),
  address: yup.string().when('$addressRequired', (addressRequired, schema) => {
    if (addressRequired) {
      return schema.test('empty', t(lang, 'error.message.global.required'), (value) => {
        return !isEmpty(value)
      })
    }
  }),
  city: yup.string().when('$cityRequired', (cityRequired, schema) => {
    if (cityRequired) {
      return schema.test('empty', t(lang, 'error.message.global.required'), (value) => {
        return !isEmpty(value)
      })
    }
  }),
})

const Page = ({data}) => {
  const pageData = data?.strapiLandingPage
  const [isSuccessful, setSuccess] = useState(false)
  const [additionalContent, setAdditionalContent] = useState(false)
  const [globalError, setGlobalError] = useState()
  const {consent} = useConsent()
  const [cookieValue, setCookieValue] = useState(INITIAL_CONSENT)
  const [counter, setCounter] = useState(0)
  const lang = pageData?.lang || SK

  useEffect(() => {
    const getCounter = async () => {
      if (pageData.showCounter) {
        // eslint-disable-next-line no-process-env
        await window.fetch(`${process.env.GATSBY_API_URL}/api/landing-pages/${pageData.strapi_id}/get-counter`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        }).then((res) => {
          return res.json()
        }).then((res) => {
          const {counter} = res
          if (counter) {
            return setCounter(counter)
          }
        })
      }
    }
    getCounter()
  }, [pageData])

  const form = useForm({
    defaultValues: {
      landingPageId: pageData.strapi_id,
    },
    resolver: yupResolver(schema(lang)),
    context: {
      answers: !isEmpty(pageData?.Answers),
      customFieldRequired: pageData.customField?.required,
      addressRequired: pageData.Address?.showAddress && pageData.Address?.addressRequired,
      cityRequired: pageData.City?.showCity && pageData.City?.cityRequired,
      isSk: lang === SK,
      emailRequired: pageData.Validation?.emailRequired,
      phoneRequired: pageData.Validation?.phoneRequired,
      postCodeRequired: pageData?.PostCode?.showPostCode && pageData.PostCode?.postCodeRequired,
    },
  })
  /* eslint-disable-next-line camelcase */
  const answers = map(pageData.Answers, ({strapi_id, text}) => ({value: String(strapi_id), label: text}))

  const handleCookieChange = () => {
    setCookieValue((prevState) => {
      if (prevState.analyticalCookies && prevState.marketingCookies) {
        return {analyticalCookies: false, marketingCookies: false}
      }
      return {analyticalCookies: true, marketingCookies: true}
    })
  }

  const handleSubmit = async (values) => {
    if (typeof window !== 'undefined') {
      consent(cookieValue)
      const answers = isArray(values.answers) ? map(values.answers, Number) : [Number(values.answers)]

      // eslint-disable-next-line no-process-env
      return await window.fetch(`${process.env.GATSBY_API_URL}/api/participants`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            ...values,
            answers: values.answers ? answers : undefined,
            referrer: window.location.href,
          },
        }),
      })
        .catch(() => {
          setGlobalError(t(lang, 'error.message.global.unavailable'))
          throw new Error('Server unavailable')
        }).then((res) => {
          return res.json()
        }).then((res) => {
          const {id, error} = res
          if (id) {
            return setSuccess(true)
          } else {
            const errorMessage = error?.message || t(lang, 'error.message.global.unknown')
            setGlobalError(errorMessage)
            throw new Error(errorMessage)
          }
        })
    }
  }

  const handleClick = () => {
    setAdditionalContent(true)
  }

  if (isSuccessful) {
    return (
      <ThankYouPage pageData={pageData} />
    )
  }

  return (
    <AppShell
        title={pageData?.pageTitle || pageData?.question}
        lang={lang}
        seo={pageData?.Seo}
        meta={pageData?.preventIndexing ? [{name: 'robots', content: 'noindex,nofollow'}] : []}
        mobileBackground={pageData?.mobileBackground?.localFile?.publicURL}
        logo={<Image image={pageData?.Logo?.media?.localFile} alt={pageData?.Logo?.alt} />}
        desktopBackground={pageData?.desktopBackground?.localFile?.publicURL}
        linkGdpr={pageData?.linkGdpr}
        gdpr={pageData?.gdpr}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ContentContainer>
            <Text component="h1" textStyle="h3" color="textInverted" width="30rem" align="center">
              {pageData.title}
            </Text>
            <Text
                component="div"
                color="textInverted"
                dangerouslySetInnerHTML={{__html: pageData.content?.data?.childMarkdownRemark?.html}}
            />
            <If condition={pageData.AdditionalContent}>
              <Choose>
                <When condition={additionalContent}>
                  <Text
                      component="div"
                      color="textInverted"
                      dangerouslySetInnerHTML={{
                        __html: pageData.AdditionalContent.content.data.childMarkdownRemark.html,
                      }}
                  />
                </When>
                <Otherwise>
                  <Button variant="plain" onClick={handleClick}>
                    {pageData.AdditionalContent.readMoreText}
                  </Button>
                </Otherwise>
              </Choose>
            </If>
            <Choose>
              <When condition={pageData.multipleChoice}>
                <CheckboxGroup name="answers" options={answers} />
              </When>
              <Otherwise>
                <RadioGroup name="answers" options={answers} />
              </Otherwise>
            </Choose>
            <If condition={pageData.customField?.text}>
              <FormLayout>
                <TextInput
                    name="customField"
                    label={pageData.customField.text}
                    required={pageData.customField.required}
                />
              </FormLayout>
            </If>
          </ContentContainer>
          <FormContainer>
            <TextInput
                name="firstName"
                label={t(lang, 'form.label.firstName')}
            />
            <TextInput
                name="lastName"
                label={t(lang, 'form.label.lastName')}
            />
            <If condition={pageData.PostCode?.showPostCode}>
              <TextInput
                  name="postCode"
                  label={t(lang, 'form.label.postCode')}
                  required={pageData.PostCode?.postCodeRequired}
                  fieldProps={{
                    onChange: (e) => {
                      form.setValue('postCode', parseInt(e.target.value) || '')
                    },
                  }}
              />
            </If>
            <TextInput
                type="email"
                name="email"
                label={t(lang, 'form.label.email')}
                required={pageData.Validation?.emailRequired}
            />
            <TextInput
                name="phone"
                label={t(lang, 'form.label.phone')}
                required={pageData.Validation?.phoneRequired}
            />
            <If condition={pageData.Address?.showAddress}>
              <TextInput
                  name="address"
                  label={t(lang, 'form.label.address')}
                  required={pageData.Address.addressRequired}
              />
            </If>
            <If condition={pageData.City?.showCity}>
              <TextInput
                  name="city"
                  label={t(lang, 'form.label.city')}
                  required={pageData.City.cityRequired}
              />
            </If>
            <ConsentSection
                name="consent"
                lang={lang}
                cookieValue={cookieValue.analyticalCookies && cookieValue.marketingCookies}
                onCookieChange={handleCookieChange}
                linkGdpr={pageData.linkGdpr}
                gdpr={pageData.gdpr}
            />
            <If condition={globalError}>
              <Text color="negative">{globalError}</Text>
            </If>
          </FormContainer>
          <CounterContainer>
            <If condition={pageData.showCounter && counter}>
              <Text textStyle="h4" align="center">
                {pageData.counterText}&nbsp;{counter}
              </Text>
            </If>
            <Button type="submit" variant="icon" icon={<PaperPlaneIcon />}>
              {pageData.submitButton}
            </Button>
          </CounterContainer>
        </form>
      </FormProvider>
    </AppShell>
  )
}

export const data = graphql`
  query PageQuery($strapi_id: Int!) {
    strapiLandingPage(strapi_id: {eq: $strapi_id}) {
      pageTitle
      lang
      content {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      gdpr
      keywords
      linkGdpr
      multipleChoice
      preventIndexing
      question
      slug
      strapi_id
      submitButton
      title
      counterText
      showCounter
      Address {
        showAddress
        addressRequired
      }
      City {
        showCity
        cityRequired
      }
      PostCode {
        showPostCode
        postCodeRequired
      }
      Validation {
        emailRequired
        phoneRequired
      }
      thankYouContent {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      Answers {
        strapi_id
        text
      }
      customField {
        text
        required
      }
      AdditionalContent {
        readMoreText
        content {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      Logo {
        alt
        media {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
        }
      }
      mobileBackground {
        localFile {
          publicURL
        }
      }
      desktopBackground {
        localFile {
          publicURL
        }
      }
      Seo {
        metaDescription
        metaTitle
        SharedImage {
          media {
            localFile {
              publicURL
            }
          }
        }
      }
      ThankYouButton {
        link
        text
      }
    }
  }
`

Page.propTypes = {
  data: PropTypes.object,
}

export default Page
