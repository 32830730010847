import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {readableColor} from 'polished'
import media from '../../utils/media'
import CheckboxIcon from '../../assets/icons/checkbox.svg'
import * as typography from '../typography/typography'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 5px solid transparent;
  border-radius: ${({theme}) => theme.border.radius.md};
  cursor: pointer;
  padding: 1rem 2.5rem 1rem 1rem;
  background-color: ${({theme}) => theme.colors.background};

  ${({helperText}) => helperText && css`
    margin-bottom: ${({theme}) => `calc(${theme.spacer} * 0.75)`};
  `}

  ${({checked}) => checked && css`
    border: 5px solid ${({theme}) => theme.colors.primary};
  `}

  ${media.down('mobile')(css`
    margin: ${({theme}) => `0 0 calc(${theme.spacer} * 2.25) 0`};
  `)}
`

const StyledCheckbox = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 1px solid ${({theme}) => `${theme.colors.secondary}`};
  border-radius: ${({theme}) => theme.border.radius.sm};
  width: 1.5rem;
  height: 1.5rem;

  svg {
    transform: translateX(25%);
    opacity: 0;
    fill: ${({theme}) => readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text)};
    path, g {
      fill: ${({theme}) => readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text)};
    }

    ${media.down('mobile')(css`
      transform: translateX(10%);
    `)}
  }
`

const StyledInput = styled.input`
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
  appearance: none;

  &:checked + ${StyledCheckbox} {
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      opacity: 1;
    }
  }
`

const StyledText = styled.span`
  padding-left: 2.25rem;
  min-width: 13.5rem;
  color: ${({theme}) => theme.colors.text};
  ${typography.h5};
`

const Checkbox = forwardRef(({name, value, label, checked, helperText, onChange, required, ...props}, ref) => {
  return (
    <StyledLabel checked={checked} helperText={helperText} {...props}>
      <StyledInput
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          checked={checked}
          ref={ref}
      />
      <StyledCheckbox>
        <CheckboxIcon />
      </StyledCheckbox>
      <StyledText>{label}</StyledText>
    </StyledLabel>
  )
})

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  helperText: PropTypes.bool,
}

export default Checkbox
