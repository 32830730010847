import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'
import FormLayout from './FormLayout'


const StyledFormContainer = styled.div`
  background-color: ${({theme}) => theme.overlays.backgroundNoBlur};
  border-radius: ${({theme}) => theme.border.radius.md};
  padding: 1.25rem 1.25rem 1.5rem;
  max-width: 60rem;
  margin-bottom: ${({theme}) => `calc(${theme.spacer} * 2.5)`};

  @supports (backdrop-filter: ${(({theme}) => theme.backdropBlur)}) {
    background-color: ${({theme}) => theme.overlays.background};
    backdrop-filter: ${(({theme}) => theme.backdropBlur)};
  }

  ${media.down('mobile')(css`
    padding: 2.375rem 2.125rem 1.75rem 1.5rem;
    width: 100%;
  `)}
`

const FormContainer = ({children}) => {
  return (
    <StyledFormContainer>
      <FormLayout>
        {children}
      </FormLayout>
    </StyledFormContainer>
  )
}

FormContainer.propTypes = {
  children: PropTypes.node,
}

export default FormContainer
