import PropTypes from 'prop-types'
import {forwardRef} from 'react'
import styled, {css} from 'styled-components'
import media from '../../utils/media'
import * as typography from '../typography/typography'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 5px solid transparent;
  border-radius: ${({theme}) => theme.border.radius.lg};
  cursor: pointer;
  padding: 1rem 2.5rem 1rem 1rem;
  background-color: ${({theme}) => theme.colors.background};

  ${({helperText}) => helperText && css`
    margin-bottom: ${({theme}) => `calc(${theme.spacer} * 0.75)`};
  `}

  ${({checked}) => checked && css`
    border: 5px solid ${({theme}) => theme.colors.primary};
  `}

  ${media.down('mobile')(css`
    margin: ${({theme}) => `0 0 calc(${theme.spacer} * 2.25) 0`};
  `)}
`

const StyledCircle = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 1px solid ${({theme}) => `${theme.colors.secondary}`};
  border-radius: ${({theme}) => theme.border.radius.xl};
  width: 1.5rem;
  height: 1.5rem;
`

const StyledInput = styled.input`
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked + ${/* sc-selector */ StyledCircle} {
    border: 6px solid ${({theme}) => theme.colors.primary};
    border-color: ${({theme}) => theme.colors.primary};
  }
`

const StyledText = styled.span`
  padding-left: ${({theme}) => `calc(${theme.spacer} * 2)`};
  color: ${({theme}) => theme.colors.text};
  ${typography.h5};

  ${media.down('mobile')(css`
    padding-left: ${({theme}) => `calc(${theme.spacer} * 1.5)`};
  `)}
`

const RadioButton = forwardRef(({name, value, label, onChange, required, checked, helperText, ...props}, ref) => {
  return (
    <StyledLabel checked={checked} helperText={helperText} {...props}>
      <StyledInput
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          checked={checked}
          ref={ref}
      />
      <StyledCircle />
      <StyledText>{label}</StyledText>
    </StyledLabel>
  )
})

RadioButton.displayName = 'RadioButton'

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  helperText: PropTypes.bool,
}

export default RadioButton
