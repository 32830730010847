import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../../utils/media'
import {t} from '../../translations'
import Text from '../typography/Text'
import Checkbox from '../form/Checkbox'
import Link from '../Link'


const StyledConsentContainer = styled.div`
  position: relative;
  padding: 0 0 0.75rem 1.5rem;

  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  line-height: 0;
  margin-top: 0.5rem;
`

const StyledHint = styled.div`
  position: absolute;
  display: flex;
  right: 0;
`

const ConsentSection = ({name, lang, cookieValue, onCookieChange, linkGdpr, gdpr, ...props}) => {
  return (
    <StyledConsentContainer>
      <StyledCheckboxContainer>
        <If condition={gdpr}>
          <StyledCheckbox
              name={name}
              label={
                <>
                  {t(lang, 'form.label.consent1')}&nbsp;
                  <Link to={linkGdpr} target="_blank" rel="noreferrer noopener">
                    {t(lang, 'form.label.consent2')}
                  </Link>
                </>
              }
              {...props}
              required
          />
          <StyledCheckbox
              label={t(lang, 'form.label.cookies')}
              value={cookieValue}
              checked={cookieValue}
              onChange={onCookieChange}
          />
        </If>
      </StyledCheckboxContainer>
      <StyledHint>
        <Text component="span" color="negative">*&nbsp;</Text>
        <Text component="span" textStyle="h6" color="textInverted">{t(lang, 'form.required')}</Text>
      </StyledHint>
    </StyledConsentContainer>
  )
}

ConsentSection.propTypes = {
  name: PropTypes.string,
  lang: PropTypes.string.isRequired,
  cookieValue: PropTypes.object,
  onCookieChange: PropTypes.func,
  linkGdpr: PropTypes.string,
  gdpr: PropTypes.bool,
}

export default ConsentSection
