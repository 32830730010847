import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'


const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 60rem;
  gap: 1rem;

  ${media.down('mobile')(css`
    flex-direction: column;
  `)}
`

const CounterContainer = ({children, ...props}) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}

CounterContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CounterContainer
