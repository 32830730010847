import PropTypes from 'prop-types'
import {get, includes} from 'lodash-es'
import {useFormContext} from 'react-hook-form'
import {For} from 'babel-plugin-jsx-control-statements'
import {ErrorMessage} from '@hookform/error-message'
import VisualRadioGroup from '../visual/form/RadioGroup'
import RadioButton from '../visual/form/RadioButton'
import HelperText from '../visual/form/HelperText'


const RadioGroup = ({name, options, helperText, required, fieldProps, ...props}) => {
  const {register, getFieldState, watch} = useFormContext()
  const {errors} = getFieldState(name)

  return (
    <>
      <VisualRadioGroup
          helperText={!get(errors, name) ? helperText : ''}
          state={get(errors, name) ? 'invalid' : 'valid'}
      >
        <For each="option" of={options}>
          <div key={option.value}>
            <RadioButton
                label={option.label}
                value={option.value}
                checked={includes(watch(name), option.value)}
                required={required}
                helperText={helperText}
                {...register('answers', fieldProps)}
                {...props}
            />
          </div>
        </For>
      </VisualRadioGroup>
      <ErrorMessage as={HelperText} name={name} state="invalid" />
    </>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
}

export default RadioGroup
