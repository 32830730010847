import PropTypes from 'prop-types'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import styled from 'styled-components'


const GatsbyImageWrapper = styled(GatsbyImage)`
  position: relative;
  width: auto;
  height: auto;

  & img,
  & > div {
    transition: opacity 500ms ease 0s !important;
  }
`

const Image = ({image, alt, loading, ...props}) => {
  if (!image) return null
  const gatsbyImage = getImage(image)

  if (!gatsbyImage) return <img src={image.publicURL} alt={alt} loading={loading} />

  return (
    <GatsbyImageWrapper
        image={gatsbyImage}
        alt={alt}
        objectFit="cover"
        loading={loading}
        {...props}
    />
  )
}

Image.defaultProps = {
  loading: 'lazy',
}

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  loading: PropTypes.oneOf(['eager', 'lazy']),
}

export default Image
