import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {keys} from 'lodash-es'
import {forwardRef} from 'react'
import media from '../../utils/media'
import * as typography from '../typography/typography'


export const stateVariants = {
  valid: css`
    border: none;
  `,
  invalid: css`
    border: 2px solid ${({theme}) => theme.colors.negative};
  `,
}

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({theme}) => theme.border.radius.md};
  background-color: ${({theme}) => `${theme.colors.background}`};
  width: 100%;
  height: 4.615rem;
  ${({state}) => stateVariants[state]}

  ${media.down('mobile')(css`
    height: 4.75rem;
  `)}
`

const StyledLabel = styled.label`
  position: relative;
  bottom: 50%;
  left: 1rem;
  transform: translateY(-50%);
  transform-origin: left;
  transition: ${({theme}) => `transform ${theme.animation}`};
  color: ${({theme}) => theme.colors.secondary};
  pointer-events: none;
  width: max-content;
  ${typography.input};

  &::first-letter {
    text-transform: capitalize;
  }

  ${({required}) => required && css`
    ::before {
      position: absolute;
      right: -1rem;
      transition: ${({theme}) => `transform ${theme.animation}, background-color ${theme.animation}`};
      color:  ${({theme}) => theme.colors.negative};
      content: '*';
    }
  `}
`

const StyledInput = styled.input`
  border: 0;
  background: none;
  cursor: text;
  padding: 0 1rem;
  min-height: 100%;

  &:focus,
  &:not(:focus):not(:placeholder-shown) {
    padding: 1rem 1rem 0;
  }

  &:focus {
    outline: none;
  }

  &:focus + ${StyledLabel},
  &:not(:placeholder-shown) + ${StyledLabel} {
    transform: translateY(-1.75rem) scale(0.65);
  }
`

const StyledHelperContainer = styled.div`
  position: relative;
  min-height: 0.5rem;
  width: 100%;
`

const StyledHelper = styled.span`
  position: absolute;
  display: block;
  padding: 0.15rem 0 1rem 0.5rem;
  text-align: left;
  color: ${({theme}) => theme.colors.textInverted};
  ${typography.h6};
`

const TextInput = forwardRef(({name, label, value, state, helperText, type, onChange, required, ...props},
  ref) => {
  return (
    <div>
      <StyledInputContainer state={state} {...props}>
        <StyledInput
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            placeholder=""
            ref={ref}
        />
        <StyledLabel htmlFor={name} required={required}>{label}</StyledLabel>
      </StyledInputContainer>
      <If condition={helperText}>
        <StyledHelperContainer>
          <StyledHelper>{helperText}</StyledHelper>
        </StyledHelperContainer>
      </If>
    </div>
  )
})

TextInput.displayName = 'TextInput'

TextInput.defaultProps = {
  type: 'text',
}

TextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  state: PropTypes.oneOf(keys(stateVariants)),
  helperText: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

export default TextInput
