import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'
import HelperText from './HelperText'


const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 31%);
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  ${media.down('mobile')(css`
    display: block;
  `)}
`

const RadioGroup = ({helperText, state, children, ...props}) => {
  return (
    <>
      <StyledContainer {...props}>
        {children}
      </StyledContainer>
      <HelperText state={state}>{helperText}</HelperText>
    </>
  )
}

RadioGroup.propTypes = {
  helperText: PropTypes.string,
  state: PropTypes.string,
  children: PropTypes.array,
}

export default RadioGroup
